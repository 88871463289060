<template>
    <a-modal v-on-click-away="closeCreditModal" :maskClosable="false" :footer="false" :closable="false"
             class="credit_modal" width="90vw" title="Title" v-model="visible">
        <div >
            <div class="credit_info d-flex  justify-content-between">
                <img class="back_to_credit" width="22" height="24" @click="back" src="@/assets/img/back.svg" alt=""/>
                <div class="d-flex  flex-column credit_inform" v-for="(item, index) in this.$parent.inform"
                     :key="index">
                    <span class="font_acrom_regular"
                          style="color: var(--color-gray-lighten); font-size: 16px; line-height: 19px">{{ item.title }}</span>
                    <p class="mb-0 mt-6" style="font-size: 20px; line-height: 24px; color: var(--color-black)">
                        {{ item.text }}
                    </p>
                </div>
                <img class="modal_close_icon mr-6 pr-6 d-none" src="@/assets/img/closeabel.svg" alt=""/>
            </div>
            <div class="credit_table ">
                <div class="d-flex credit_table_top">
                    <img style="margin-top: 2px" width="32" height="32" src="@/assets/img/ev.svg" alt=""/>
                    <h3 class="font_acrom_medium ml-18" style="font-size: 32px; line-height: 38px">
                        {{ $parent.selectedCalcType !== 'deposit' ? $t('components.common.repaymentSchedule') : $t('components.common.interestCalculationSchedule') }}</h3>
                </div>
                <div>
                    <div class="credit_table_header d-flex" style="margin-bottom:6px"
                         :class="$parent.selectedCalcType == 'deposit' ? 'justify-content-between' : ''">
                        <p class="font_acrom_regular"
                           style="font-size: 16px !important; line-height: 19px !important; color: var(--color-gray-lighten) !important">
                            {{ $t("components.common.date") }}</p>
                        <p class="font_acrom_regular" v-if="this.$parent.creditType != 2"
                           style="font-size: 16px !important; line-height: 19px !important; color: var(--color-gray-lighten) !important">
                            {{ $t("components.common.days") }}</p>
                        <p class="font_acrom_regular"
                           style="font-size: 16px !important; line-height: 19px !important; color: var(--color-gray-lighten) !important">
                            {{ $parent.selectedCalcType != 'deposit' ? $t('components.common.mainDebt') : '' }} </p>
                        <p class="font_acrom_regular"
                           style="font-size: 16px !important; line-height: 19px !important; color: var(--color-gray-lighten) !important">
                            {{ $parent.selectedCalcType != "deposit" ? $t('components.common.principalPayments') : $t('components.common.accrual') }}</p>
                        <p v-if="$parent.selectedCalcType !== 'deposit' " class="font_acrom_regular"
                           style="font-size: 16px !important; line-height: 19px !important; color: var(--color-gray-lighten) !important">
                            {{ $t("components.common.paymentsOnCreditInterest") }}</p>
                        <p v-if="$parent.selectedCalcType !== 'deposit' " class="font_acrom_regular"
                           style="font-size: 16px !important; line-height: 19px !important; color: var(--color-gray-lighten) !important">
                            {{ $t("components.common.altogether") }}</p>
                    </div>
                    <div class="credit_body_scroll" v-if="visible">
                        <div v-if="this.$parent.credit_table">
                            <div id="credit_body  "
                                 :class="$parent.selectedCalcType === 'deposit' ? 'justify-content-between' : ''"
                                 class="credit_table_info d-flex"
                                 v-for="(item, index) in this.$parent.credit_table.table" :key="index"
                                 style="height: 48px">
                                <p>{{ dtFmt(item.pay_date, "DD.MM.YYYY") }}</p>
                                <p v-if="$parent.creditType != 2">{{ item.pay_for_days }}</p>
                                <p >{{ $parent.selectedCalcType !== 'deposit' ? item.remain_debt : '' }}</p>
                                <p>{{ item.monthly_main_debt || item.monthly_payment  }}</p>
                                <p v-if="$parent.selectedCalcType !== 'deposit' ">{{item.monthly_percent_debt }}</p>
                                <p v-if="$parent.selectedCalcType !== 'deposit' ">{{ item.monthly_total_payment}}</p>

                            </div>
                        </div>
                        <div class="credit_table_info d-flex pb-20"
                             :class="$parent.selectedCalcType === 'deposit' ? 'justify-content-between' : ''"
                             style="height: 48px">
                            <p></p>
                            <p></p>
                            <p v-if="$parent.creditType != '2'"></p>
                            <p>{{ $t("components.common.total") }}: {{this.$parent.credit_table.debt_total || this.$parent.credit_table.interest}}</p>
                            <p v-if="$parent.selectedCalcType !== 'deposit' && this.$parent.credit_table">{{ $t("components.common.total") }}:
                                {{ this.$parent.credit_table.percent_total}}</p>
                            <p v-if="$parent.selectedCalcType !== 'deposit'">{{ $t("components.common.total") }}:
                                {{  this.$parent.credit_table.total_total}}</p>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </a-modal>
</template>

<script>
import moment from "moment";
import ListUtils from "@/mixins/ListUtils";
import {directive as onClickAway} from "vue-clickaway";

export default {
    directives: {
        onClickAway: onClickAway
    },
    mixins: [ListUtils],
    data() {
        return {
            visible: false,
            mainCreditDebt: 0,
            allCreditDebt: 0,
            allPercentDebt: 0,
            calcType: ''
        }
    },
    created() {
        this.calcType = this.$parent.selectedCalcType
    },
    methods: {
        openModal() {
            this.visible = true
        },
        dtFmt(date, format = "YYYY-MM-DD") {
            return moment(date).format(format);
        },
        nFormatter(num, digits) {
            const lookup = [
                {
                    value: 1,
                    symbol: ""
                },
                {
                    value: 1e3,
                    symbol: "k"
                },
                {
                    value: 1e6,
                    symbol: "M"
                },
                {
                    value: 1e9,
                    symbol: "G"
                },
                {
                    value: 1e12,
                    symbol: "T"
                },
                {
                    value: 1e15,
                    symbol: "P"
                },
                {
                    value: 1e18,
                    symbol: "E"
                }
            ];
            const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
            let item = lookup.slice().reverse().find(function (item) {
                return num >= item.value;
            });
            return item ?
                (num / item.value).toFixed(digits).replace(rx, "$1") + item.symbol :
                "0";
        },
        back() {
            this.visible = false
            this.$emit('back')
        },
        closeCreditModal(e) {
            if (e?.target) {
                if (!this.$el.contains(e?.target) && !e?.target.classList.contains('close_icon')) {
                    if (this.visible) {
                        this.visible = false
                        this.$parent.$refs?.creditModal.closeModal()
                        this.$parent.isVisibleProductModal = false
                    }
                }
            }
        },
        closeAllModal() {
            this.visible = false
            this.$parent.isVisibleCreditTable = false
            this.$parent.$refs.creditModal.closeModal()
            this.$parent.isVisibleProductModal = false
        },

    },
    mounted() {
        this.$watch("$parent.credit_table.table", (new_value) => {
                let mainDebit = 0
                let fixed = this.$parent.selectedCalcType !== 'deposit' ? 0 : this.$parent.currencyType !== 'UZS' ? 2 : 0
                let allCreditDebit = 0
                for (const item of Object.values(new_value)) {
                    mainDebit += Number(item.monthly_main_debt?.replaceAll(' ', '') || item.monthly_payment?.replaceAll(' ', ''))
                    allCreditDebit += Number(item.monthly_total_payment?.replaceAll(' ', ''))
                    this.allCreditDebt = Number(allCreditDebit).toFixed(fixed)
                    this.mainCreditDebt = Number(mainDebit).toFixed(fixed)
                }
            }
        );

    },
}
</script>

<style lang="scss">
.back_to_credit {
    cursor: pointer;
}

</style>
